
import { BSelect } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent, shallowRef } from 'vue';

const frameworks = [
	'Angular',
	'Angular 2',
	'Aurelia',
	'Backbone',
	'Ember',
	'jQuery',
	'Meteor',
	'Node.js',
	'Polymer',
	'React',
	'RxJS',
	'Vue.js'
];

export default defineComponent({
	name: 'select-example',
	components: {
		BSelect,
		BField
	},
	setup() {
		const selectedFramework = shallowRef(null);

		return {
			frameworks,
			selectedFramework
		};
	}
});
