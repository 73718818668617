
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import ApiView from '../../../../../components/apiView/ApiView.vue';
import { ExampleView } from '../../../../../components/exampleView';
import { apis } from './apis';
import MultipleExample from './examples/MultipleExample.vue';
import MultipleExampleCode from '!!raw-loader!./examples/MultipleExample.vue';
import ObjectArrayExample from './examples/ObjectArrayExample.vue';
import ObjectArrayExampleCode from '!!raw-loader!./examples/ObjectArrayExample.vue';
import Simple from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';

export default defineComponent({
	name: 'select-documentation',
	components: {
		ObjectArrayExample,
		MultipleExample,
		ExampleView,
		ApiView,
		Simple,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			SimpleCode,
			ObjectArrayExampleCode,
			MultipleExampleCode
		};
	}
});
